import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function Blank() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(true);
  const history = useHistory();
  const handleclick = (lan) => {
    {
      setLanguage(false);
      localStorage.setItem("language", lan);
      i18n.changeLanguage(lan);
      history.push("/home");
    }
  };
  return (
    <div>
      {language ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative w-full bg-yellow-50 outline-none focus:outline-none grid place-items-center">
                <div className="relative p-6 flex-auto text-2xl text-center">
                  <button
                    className="w-full bg-green-400 text-white active:bg-pink-600 font-bold uppercase text-lg px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    onClick={() => handleclick("en")}
                  >
                    <span className="ml-2">English</span>
                  </button>
                  <button
                    className="w-full bg-green-400 text-white active:bg-pink-600 font-bold uppercase text-lg px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    onClick={() => handleclick("hi")}
                  >
                    <span className="ml-2">हिंदी</span>
                  </button>
                  <button
                    className="w-full bg-green-400 text-white active:bg-pink-600 font-bold uppercase text-lg px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    onClick={() => handleclick("kn")}
                  >
                    <span className="ml-2">ಕನ್ನಡ</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}

export default Blank;
