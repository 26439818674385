import React, { useState, useEffect } from "react";
import { downloadofflineToken } from "../request/helper";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FcPhone, FcBusinessman, FcLeftUp2, FcSupport } from "react-icons/fc";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";

import en from "../audio/download_en.mp3";
import hi from "../audio/download_hi.mp3";
import kn from "../audio/download_kn.mp3";
function DownloadToken() {
  const { t, i18n } = useTranslation();
  const [sound, setSound] = useState({ sound: "" });
  const [mute, setMute] = useState(false);

  useEffect(() => {
    let langauge = localStorage.getItem("language");
    // console.log(langauge);
    if (langauge == "en") {
      setSound({ sound: new Audio(en) });
    }
    if (langauge == "kn") {
      setSound({ sound: new Audio(kn) });
    }
    if (langauge == "hi") {
      setSound({ sound: new Audio(hi) });
    }
    // if (localStorage.getItem("mute")) {
    //   setMute(localStorage.getItem("mute"));
    // console.log(localStorage.getItem("mute"));
    // handleSound(!localStorage.getItem("mute"));
    // }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, []);

  useEffect(() => {
    let ss = sound.sound;
    if (typeof ss === "object") {
      ss.play();
      console.log(ss);
    }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, [sound]);

  const handleSound = (unmute) => {
    if (sound.sound) {
      let ss = sound.sound;
      if (typeof ss === "object") {
        console.log("aayaya", unmute);
        if (unmute) {
          ss.volume = 1;
        } else {
          ss.volume = 0;
        }
      }
    }
  };
  const [data, setData] = useState({
    name: "",
    mobile_number: "",
    gender: "",
    beneficiary_reference_id: "",
    age_group: "",
    vaccine: "",
    dose_number: "",
  });
  const history = useHistory();
  const age = ["18-45", "45-more"];
  const handlesubmit = async (e) => {
    e.preventDefault();
    let final = { beneficiaries: data };
    let response = await downloadofflineToken(final);
    console.log(response);
    let token_response = {};
    token_response.data = [];
    token_response.data.push(response.data);
    if (Object.keys(response.data).length > 0) {
      history.push("/dtoken", { response: JSON.stringify(token_response) });
    } else {
      toast.error("Please generate your Token");
      history.push("/offline");
    }
  };

  return (
    <div className="w-full">
      <div className="flex-none lg:flex justify-center pt-4">
        <div className="w-full max-w-2xl  ml-4">
          <form
            className="bg-gray-100 shadow-lg rounded px-8 pt-6 pb-8 mb-4"
            onSubmit={handlesubmit}
          >
            <div className="flex justify-center pt-4">
              <h1 className="text-gray-500 text-xl">
                {t("components.downloadtoken.title")}
              </h1>
            </div>
            <br />
            <label className="lining required block text-gray-500 text-sm font-bold mb-2">
              <FcPhone />
              {t("components.downloadtoken.input1")}
            </label>
            <br />
            <input
              className="shadow appearance-none border max-w-lg rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
              type="number"
              name="mobile_number"
              value={data.mobile_number}
              onChange={(e) => {
                if (e.target.value.length < 11) {
                  setData({ ...data, mobile_number: e.target.value });
                }
              }}
              required
            />
            <br />
            <label className="lining block text-gray-500 text-sm font-bold mb-2">
              <FcBusinessman />
              {t("components.downloadtoken.input2")}
            </label>
            <br />
            <input
              className="shadow appearance-none border rounded w-full max-w-lg py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
              type="text"
              name="name"
              value={data.name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
            />
            <br />
            <label className="lining block text-gray-500 text-sm font-bold mb-2">
              <FcLeftUp2 />
              {t("components.downloadtoken.input3")}
            </label>
            <br />
            <input
              className="shadow appearance-none border max-w-lg rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
              type="text"
              name="beneficiary_reference_id"
              value={data.beneficiary_reference_id}
              onChange={(e) => {
                setData({ ...data, beneficiary_reference_id: e.target.value });
              }}
            />
            <br />

            <label className="lining block text-gray-500 text-sm font-bold mb-2">
              <FcSupport />
              {t("components.downloadtoken.input4")}
            </label>
            <br />
            <select
              className="relative w-full max-w-xs mx-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={data.age_group}
              onChange={(e) => {
                setData({ ...data, age_group: e.target.value });
              }}
            >
              <option
                className="absolute z-10 mt-1 w-full bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                value=""
                selected
                disabled
                hidden
              >
                {t("components.downloadtoken.choose")}
              </option>
              {age.map((x) => (
                <option
                  className="absolute z-10 mt-1 w-full bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  key={x}
                  value={x}
                >
                  {x}
                </option>
              ))}
            </select>
            <br />
            <div className="flex justify-center pt-4">
              <button className="lining bg-gray-500 mt-3 hover:bg-gray-700 text-gray-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <BsFillPatchCheckFill />
                {t("components.downloadtoken.submit")}
              </button>
            </div>
          </form>
        </div>
        <div className="absolute left-0 top-0 mr-16 px-2">
          {mute ? (
            <GiSpeakerOff
              className="text-4xl"
              onClick={() => {
                setMute(false);
                handleSound(mute);
              }}
            />
          ) : (
            <GiSpeaker
              className="text-4xl"
              onClick={() => {
                setMute(true);
                handleSound(mute);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DownloadToken;
