import React, { useState, useEffect } from "react";
import { getalltokens } from "../request/helper";
import ReactToExcel from "react-html-table-to-excel";
import { MDBDataTableV5 } from "mdbreact";
import { useTranslation } from "react-i18next";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import en from "../audio/details_en.mp3";
import hi from "../audio/details_hi.mp3";
import kn from "../audio/details_kn.mp3";

function TokenDashboard() {
  const { t, i18n } = useTranslation();
  const [sound, setSound] = useState({ sound: "" });
  const [mute, setMute] = useState(false);
  let col1 = t("components.tokendash.table.1");
  let col2 = t("components.tokendash.table.2");
  let col3 = t("components.tokendash.table.3");
  let col4 = t("components.tokendash.table.4");
  let col5 = t("components.tokendash.table.5");
  let col6 = t("components.tokendash.table.6");
  let col7 = t("components.tokendash.table.7");
  const [data, setData] = useState(undefined);
  const [filterday, setFilterday] = useState(false);
  const [filtertime, setFiltertime] = useState(false);
  const [filtermonth, setFiltermonth] = useState(false);
  const filter_methods = [
    t("components.tokendash.day"),
    t("components.tokendash.timeline"),
    t("components.tokendash.month"),
  ];
  useEffect(() => {
    let langauge = localStorage.getItem("language");
    // console.log(langauge);
    if (langauge == "en") {
      setSound({ sound: new Audio(en) });
    }
    if (langauge == "kn") {
      setSound({ sound: new Audio(kn) });
    }
    if (langauge == "hi") {
      setSound({ sound: new Audio(hi) });
    }
    // if (localStorage.getItem("mute")) {
    //   setMute(localStorage.getItem("mute"));
    // console.log(localStorage.getItem("mute"));
    // handleSound(!localStorage.getItem("mute"));
    // }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, []);

  useEffect(() => {
    let ss = sound.sound;
    if (typeof ss === "object") {
      ss.play();
      console.log(ss);
    }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, [sound]);

  const handleSound = (unmute) => {
    if (sound.sound) {
      let ss = sound.sound;
      if (typeof ss === "object") {
        console.log("aayaya", unmute);
        if (unmute) {
          ss.volume = 1;
        } else {
          ss.volume = 0;
        }
      }
    }
  };
  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: col1,
        field: "name",
        width: 150,
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "Name",
        },
      },
      {
        label: col2,
        field: "token",
        sort: "asc",
        width: 100,
      },
      {
        label: col3,
        field: "mobile_number",
        sort: "asc",
        width: 100,
      },
      {
        label: col4,
        field: "vaccine",
        sort: "asc",
        width: 100,
      },
      {
        label: col5,
        field: "gender",
        sort: "asc",
        width: 100,
      },
      {
        label: col6,
        field: "beneficiary_reference_id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Age Group",
        field: "age_group",
        sort: "asc",
        width: 50,
      },
      {
        label: col7,
        field: "date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Dose",
        field: "dose_number",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [],
  });
  const [dates, setDates] = useState({
    day: "",
    from: "",
    till: "",
    month: "",
  });
  const month = [
    {
      id: 1,
      value: t("components.tokendash.months.1"),
    },
    {
      id: 2,
      value: t("components.tokendash.months.2"),
    },
    {
      id: 3,
      value: t("components.tokendash.months.3"),
    },
    {
      id: 4,
      value: t("components.tokendash.months.4"),
    },
    {
      id: 5,
      value: t("components.tokendash.months.5"),
    },
    {
      id: 6,
      value: t("components.tokendash.months.6"),
    },
    {
      id: 7,
      value: t("components.tokendash.months.7"),
    },
    {
      id: 8,
      value: t("components.tokendash.months.8"),
    },
    {
      id: 9,
      value: t("components.tokendash.months.9"),
    },
    {
      id: 10,
      value: t("components.tokendash.months.10"),
    },
    {
      id: 11,
      value: t("components.tokendash.months.11"),
    },
    {
      id: 12,
      value: t("components.tokendash.months.12"),
    },
  ];

  function sortFunction(a, b) {
    if (new Date(b.date) === new Date(a.date)) {
      return a.name < b.name;
    }
    return new Date(b.date) - new Date(a.date);
  }

  const tokendata = async () => {
    let res = await getalltokens();
    let data = res.data;
    data.sort(sortFunction);
    for (let i = 0; i < data.length; i++) {
      if (data[i].date) {
        data[i].date = data[i].date.split("-").reverse().join("-");
      }
    }
    // let reversed_data = data;
    // reversed_data.reverse();
    setData(data);
    setDatatable({ ...datatable, rows: data });
    return res;
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    let res = await getalltokens(dates);
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].date) {
        res.data[i].date = res.data[i].date.split("-").reverse().join("-");
      }
    }
    let reversed_data = res.data;
    reversed_data.reverse();
    setData(reversed_data);
    setDatatable({ ...datatable, rows: reversed_data });
    console.log(datatable);
  };
  const clear = () => {
    setFilterday(false);
    setFiltertime(false);
    setFiltermonth(false);
    tokendata();
  };
  useEffect(() => {
    tokendata();
  }, []);
  return (
    <div className="shadow-lg bg-gray-100 mx-16 mt-16 mx-auto max-w-5xl">
      <div className="flex justify-center pt-4">
        <h1 className="text-gray-500 text-3xl mt-4">
          {t("components.tokendash.title")}
        </h1>
      </div>
      <label className="block text-gray-500 text-sm font-bold mb-2 ml-2">
        {t("components.tokendash.filter")}:
      </label>
      <select
        className="relative w-full max-w-xs mx-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        onChange={(e) => {
          if (
            e.target.value === "Date" ||
            e.target.value === "दिन" ||
            e.target.value === "ದಿನ"
          ) {
            setFilterday(true);
            setFiltertime(false);
            setFiltermonth(false);
            setDates({ day: "", from: "", till: "", month: "" });
          }
          if (
            e.target.value === "Timeline" ||
            e.target.value === "समय" ||
            e.target.value === "ಟೈಮ್ಲೈನ್"
          ) {
            setFilterday(false);
            setFiltertime(true);
            setFiltermonth(false);
            setDates({ day: "", from: "", till: "", month: "" });
          }
          if (
            e.target.value === "Month" ||
            e.target.value === "महीना" ||
            e.target.value === "ತಿಂಗಳು"
          ) {
            setFilterday(false);
            setFiltertime(false);
            setFiltermonth(true);
            setDates({ day: "", from: "", till: "", month: "" });
          }
        }}
      >
        <option
          className="absolute z-10 mt-1 w-full bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          value=""
          selected
          disabled
          hidden
        >
          {t("components.tokendash.choose")}
        </option>
        {filter_methods.map((x) => (
          <option
            className="absolute z-10 mt-1 w-full bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
            key={x}
            value={x}
          >
            {x}
          </option>
        ))}
      </select>
      <form className="bg-gray rounded pt-2 pb-2 mb-2" onSubmit={handlesubmit}>
        {filterday && (
          <div>
            <label className="block text-gray-500 text-sm font-bold mb-2 ml-2">
              {t("components.tokendash.day")}
            </label>
            <input
              className="shadow ml-2 appearance-none border max-w-lg rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
              type="date"
              value={dates.day}
              onChange={(e) => {
                setDates({ ...dates, day: e.target.value });
              }}
            />
          </div>
        )}
        {filtertime && (
          <div>
            <label className="block text-gray-500 text-sm font-bold mb-2 ml-2">
              {t("components.tokendash.from")}
            </label>
            <input
              className="shadow ml-2 appearance-none border max-w-lg rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
              type="date"
              value={dates.from}
              onChange={(e) => {
                setDates({ ...dates, from: e.target.value });
              }}
            />
            <label className="block text-gray-500 text-sm font-bold mb-2 ml-2">
              {t("components.tokendash.to")}
            </label>
            <input
              className="shadow ml-2 appearance-none border max-w-lg rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
              type="date"
              value={dates.till}
              onChange={(e) => {
                setDates({ ...dates, till: e.target.value });
              }}
            />
          </div>
        )}
        {filtermonth && (
          <div>
            <label className="block text-gray-500 text-sm font-bold mb-2 ml-2">
              {t("components.tokendash.month")}
            </label>
            <select
              className="relative w-full max-w-xs mx-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={dates.month}
              onChange={(e) => {
                setDates({ ...dates, month: e.target.value });
              }}
            >
              <option
                className="absolute z-10 mt-1 w-full bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                value=""
                selected
                disabled
                hidden
              >
                {t("components.tokendash.choose")}
              </option>
              {month.map((x) => (
                <option
                  className="absolute z-10 mt-1 w-full bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  key={x.id}
                  value={x.id}
                >
                  {x.value}
                </option>
              ))}
            </select>
          </div>
        )}
        {(filterday || filtermonth || filtertime) && (
          <div className="ml-2 pt-1">
            <button className="bg-gray-500 mt-3 hover:bg-gray-700 text-gray-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              {t("components.tokendash.filter")}
            </button>
            <button
              className="bg-gray-500 ml-3 mt-3 hover:bg-gray-700 text-gray-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={clear}
            >
              {t("components.tokendash.clear")}
            </button>
          </div>
        )}
      </form>
      <div className="bg-gray-100 shadow-lg overflow-x-auto overflow-y-auto max-h-screen">
        <MDBDataTableV5
          // id="table-to-xls"
          className="bg-gray-100"
          hover
          entriesOptions={[5, 10, 15, 20]}
          entries={10}
          pagesAmount={10}
          data={datatable}
        />
      </div>
      <div style={{ display: "none" }}>
        <MDBDataTableV5
          id="table-to-xls"
          hover
          entries={1000000}
          // pagesAmount={10}
          data={datatable}
        />
      </div>
      <div className="flex justify-center">
        <ReactToExcel
          className="bg-gray-700 mt-4 mb-4 hover:bg-gray-700 text-gray-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          table="table-to-xls"
          filename="excelFile"
          sheet="sheet 1"
          buttonText={t("components.tokendash.export")}
        />
      </div>
      <div className="absolute left-0 top-0 mr-16 px-2">
        {mute ? (
          <GiSpeakerOff
            className="text-4xl"
            onClick={() => {
              setMute(false);
              handleSound(mute);
            }}
          />
        ) : (
          <GiSpeaker
            className="text-4xl"
            onClick={() => {
              setMute(true);
              handleSound(mute);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default TokenDashboard;
