import React, { useEffect, useState } from "react";
import { generateToken, getslotData } from "../request/helper";
import { useHistory } from "react-router-dom";
import Slotdata from "./Slotdata";
import { useTranslation } from "react-i18next";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import en from "../audio/user_en.mp3";
import hi from "../audio/user_hi.mp3";
import kn from "../audio/user_kn.mp3";
export default function UserVerification() {
  const [sound, setSound] = useState({ sound: "" });
  const [mute, setMute] = useState(false);
  useEffect(() => {
    let langauge = localStorage.getItem("language");
    // console.log(langauge);
    if (langauge == "en") {
      setSound({ sound: new Audio(en) });
    }
    if (langauge == "kn") {
      setSound({ sound: new Audio(kn) });
    }
    if (langauge == "hi") {
      setSound({ sound: new Audio(hi) });
    }
    // if (localStorage.getItem("mute")) {
    //   setMute(localStorage.getItem("mute"));
    // console.log(localStorage.getItem("mute"));
    // handleSound(!localStorage.getItem("mute"));
    // }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, []);

  useEffect(() => {
    let ss = sound.sound;
    if (typeof ss === "object") {
      ss.play();
      console.log(ss);
    }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, [sound]);

  const handleSound = (unmute) => {
    if (sound.sound) {
      let ss = sound.sound;
      if (typeof ss === "object") {
        console.log("aayaya", unmute);
        if (unmute) {
          ss.volume = 1;
        } else {
          ss.volume = 0;
        }
      }
    }
  };

  const { t, i18n } = useTranslation();
  const [beneficiaries, setBeneficiaries] = useState(undefined);
  const vaccines = ["COVISHIELD", "COVAXIN"];
  const [slot, setSlot] = useState(undefined);
  const [subm, setSubm] = useState(true);
  const history = useHistory();
  const handlecheckbox = (ref_id) => {
    var x = [...beneficiaries];
    for (var i = 0; i < x.length; i++) {
      if (x[i].beneficiary_reference_id === ref_id) {
        if (x[i].is_checked === true) {
          x[i].vaccine_type = "";
        }
        x[i].is_checked = !x[i].is_checked;
      }
    }
    setBeneficiaries(x);
  };
  const handleclick = async () => {
    var x = [...beneficiaries];
    for (var i = 0; i < x.length; i++) {
      x[i].vaccine = x[i].vaccine_type;
      delete x[i].vaccine_type;
    }
    let y = [];
    for (var i = 0; i < x.length; i++) {
      if (x[i].is_checked) {
        y.push(x[i]);
      }
    }
    let request = {};
    request.beneficiaries = y;
    request.mobile_number = JSON.parse(
      localStorage.getItem("user_details")
    ).phone_number;
    console.log(request);
    let response = await generateToken(request);
    history.push("/token", { response: JSON.stringify(response) });
  };
  const handleselect = (e, ref_id) => {
    var x = [...beneficiaries];
    for (var i = 0; i < x.length; i++) {
      if (x[i].beneficiary_reference_id === ref_id) {
        x[i].vaccine_type = e.target.value;
        x[i].is_checked = true;
      }
    }
    setBeneficiaries(x);
  };
  useEffect(() => {
    async function fetch_beneficary() {
      let x = JSON.parse(localStorage.getItem("benificiaries"));
      if (x) {
        for (var i = 0; i < x.length; i++) {
          x[i].is_checked = false;
          if (!x[i].token_number) {
            x[i].token_number = "";
            x[i].vaccine_type = "";
          }
          if (x[i].vaccine) {
            x[i].checked = true;
            x[i].vaccine_type = x[i].vaccine;
          }
        }
        async function get_slotData() {
          let response = await getslotData();
          if (response.data.length > 0) {
            setSlot(response.data);
          }
        }
        setBeneficiaries(x);
      }
    }
    fetch_beneficary();
  }, []);
  useEffect(() => {
    if (beneficiaries) {
      let flag = 0;
      let count = 0;
      for (var i = 0; i < beneficiaries.length; i++) {
        if (beneficiaries[i].is_checked) {
          count += 1;
          if (beneficiaries[i].vaccine_type === "") {
            flag = 1;
            break;
          }
        }
      }
      if (flag === 0 && count > 0) {
        setSubm(false);
      } else {
        setSubm(true);
      }
    }
  }, [beneficiaries]);
  return (
    <div className="w-full">
      <div>
        <div className="flex justify-center py-8 text-sm md:text-3xl text-gray-700">
          <h1>{t("components.user.title")}</h1>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-1 lg:col-span-1"></div>
          <div className="col-span-11 lg:col-span-5">
            {beneficiaries &&
              beneficiaries.map((items) => (
                <div
                  key={items.beneficiary_reference_id}
                  className="bg-gray-100 shadow-lg rounded  px-8 pt-6 pb-8 mb-4"
                >
                  <div className="flex font-bold text-gray-700 text-3xl">
                    <input
                      className="mr-3"
                      checked={items.is_checked}
                      onChange={() =>
                        handlecheckbox(items.beneficiary_reference_id)
                      }
                      type="checkbox"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <div className="ml-4">{items.name} </div>
                  </div>
                  <hr />
                  <br />
                  <div className="font-medium text-gray-700 text-xl">
                    <h2>
                      {t("components.user.ref")}:{" "}
                      {items.beneficiary_reference_id}
                    </h2>
                    <h2>
                      {t("components.user.yob")}: {items.birth_year}
                    </h2>
                    {items.vaccine ? (
                      <>
                        <h2>
                          {t("components.user.vac")}: {items.vaccine}
                        </h2>
                        <h3>
                          {t("components.user.dose1")}:{items.dose1_date}
                        </h3>
                        <h3>
                          {t("components.user.dose2")}:{items.dose2_date}
                        </h3>
                      </>
                    ) : (
                      [
                        items.dose1_date
                          ? ((
                              <h2>
                                {t("components.user.dose1")}: {items.dose1_date}
                              </h2>
                            ),
                            [
                              items.dose2_date ? (
                                <h2>
                                  {t("components.user.dose2")}:{" "}
                                  {items.dose2_date}
                                </h2>
                              ) : (
                                [
                                  <p>
                                    {t("components.user.vactype")}
                                    <select
                                      className="relative mx-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                      onChange={(e) =>
                                        handleselect(
                                          e,
                                          items.beneficiary_reference_id
                                        )
                                      }
                                      value={items.vaccine_type}
                                    >
                                      <option
                                        className="absolute z-10 mt-1 w-full bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                        value=""
                                        selected
                                        disabled
                                        hidden
                                      >
                                        {t("components.user.choose")}
                                      </option>
                                      {vaccines.map((x) => (
                                        <option
                                          className="absolute z-10 mt-1 w-full bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                          key={x}
                                          value={x}
                                        >
                                          {x}
                                        </option>
                                      ))}
                                    </select>
                                    {items.is_checked
                                      ? [
                                          items.vaccine_type ? null : (
                                            <p className="text-gray-500 text-xs italic">
                                              {t("components.user.vachoose")}
                                            </p>
                                          ),
                                        ]
                                      : null}
                                  </p>,
                                ]
                              ),
                            ])
                          : [
                              <p>
                                {t("components.user.d1choose")} :
                                <select
                                  className="relative mx-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  onChange={(e) =>
                                    handleselect(
                                      e,
                                      items.beneficiary_reference_id
                                    )
                                  }
                                  value={items.vaccine_type}
                                >
                                  <option
                                    className="absolute z-10 mt-1 w-full bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                    value=""
                                    selected
                                    disabled
                                    hidden
                                  >
                                    {t("components.user.choose")}
                                  </option>
                                  {vaccines.map((x) => (
                                    <option
                                      className="absolute z-10 mt-1 w-full bg-gray-100 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                      key={x}
                                      value={x}
                                    >
                                      {x}
                                    </option>
                                  ))}
                                </select>
                                {items.is_checked
                                  ? [
                                      items.vaccine_type ? null : (
                                        <p className="text-gray-500 text-xs italic">
                                          {t("components.user.vachoose")}
                                        </p>
                                      ),
                                    ]
                                  : null}
                              </p>,
                            ],
                      ]
                    )}
                  </div>
                </div>
              ))}
            <div className="flex flex-row justify-center">
              <div>
                <button
                  disabled={subm}
                  onClick={handleclick}
                  className={
                    subm
                      ? "lining bg-gray-300 text-gray-100 font-bold py-2 px-4 rounded"
                      : "lining bg-gray-500 hover:bg-gray-700 text-gray-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  }
                >
                  <BsFillPatchCheckFill />
                  {t("components.user.gen")}
                </button>
                {subm ? (
                  <p className=" text-gray-500 text-xs italic">
                    {t("components.user.war")}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-span-0 lg:col-span-1"></div>
          <div className="col-span-11 lg:col-span-4">
            <Slotdata />
          </div>
        </div>
      </div>
      {/* <div className="h-screen max-w-xl bg-gray-100 shadow-lg rounded  px-8 pt-6 pb-8 mb-4 ml-4">
        {slot ? (
          slot.reverse().map((it) => {
            <div>
              <h1>
                <b>Slot Date: </b>
                {it.slot_date}
              </h1>
              <h1>
                <b>Vaccine Type:</b>
                {it.vaccine_type}
              </h1>
              <br />
              <h2>
                <b>Dose Type:</b>
                {it.dose_type}
              </h2>
              <h2>
                <b>Age Group:</b>
                {it.age_group}
              </h2>
              <h2>
                <b>Count:</b>
                {it.count}
              </h2>
            </div>;
          })
        ) : (
          <h1 className="text-xl">No slot Data! Yet Please Try again later</h1>
        )}
      </div> */}

      <div className="absolute left-0 top-0 mr-16 px-2">
        {mute ? (
          <GiSpeakerOff
            className="text-4xl"
            onClick={() => {
              setMute(false);
              handleSound(mute);
            }}
          />
        ) : (
          <GiSpeaker
            className="text-4xl"
            onClick={() => {
              setMute(true);
              handleSound(mute);
            }}
          />
        )}
      </div>
    </div>
  );
}
