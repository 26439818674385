import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getslotData } from "../request/helper";
import { FcCalendar } from "react-icons/fc";

function Slotdata() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(undefined);
  const slotdata = async () => {
    let res = await getslotData();
    if (res.data.length > 0) {
      setData(res.data);
    }
    return res;
  };

  useEffect(() => {
    slotdata();
  }, []);

  return (
    <div className="shadow-lg rounded bg-gray-100 max-w-3xl mt-0 md:mt-16">
      <div className="flex justify-center pt-4 bg-gray-100">
        <h1 className="text-gray-500 text-lg md:text-3xl mt-4 lining">
          <FcCalendar />
          {t("components.slotdata.title")}
        </h1>
      </div>
      {data ? (
        <table className="table-fixed mx-auto shadow-lg rounded">
          <thead>
            <tr>
              <th className=" border text-sm text-left md:text-xl px-2 md:px-8 py-4">
                {t("components.slotdata.header1")}
              </th>
              <th className=" border text-sm text-left md:text-xl px-2 md:px-8 py-4">
                {t("components.slotdata.header2")}
              </th>
              <th className=" border text-sm text-left md:text-xl px-2 md:px-8 py-4">
                {t("components.slotdata.header3")}
              </th>
              <th className=" border text-sm text-left md:text-xl px-2 md:px-8 py-4">
                {t("components.slotdata.header4")}
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((each) => (
                <>
                  <tr>
                    <td className="border text-sm md:text-xl px-2 md:px-8 py-4">
                      {each.vaccine_type}
                    </td>
                    <td className="border text-sm md:text-xl px-2 md:px-8 py-4">
                      {each.count}
                    </td>
                    <td className="border text-sm md:text-xl px-2 md:px-8 py-4">
                      {each.dose_type}
                    </td>
                    <td className="border text-sm md:text-xl px-2 md:px-8 py-4">
                      {each.age_group}
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="flex justify-center mt-2">
          <span className="text-gray-500 font-black text-lg md:text-2xl">
            {t("components.slotdata.message")}
          </span>
        </div>
      )}
    </div>
  );
}

export default Slotdata;
