import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import QRCode from "qrcode";
import GenericPdfDownloader from "./GenericPdfDownloader";
import { useTranslation } from "react-i18next";
import "./token.css";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";

import en from "../audio/token_en.mp3";
import hi from "../audio/token_hi.mp3";
import kn from "../audio/token_kn.mp3";
function Token({ location }) {
  const [sound, setSound] = useState({ sound: "" });
  const [mute, setMute] = useState(false);
  useEffect(() => {
    let langauge = localStorage.getItem("language");
    // console.log(langauge);
    if (langauge == "en") {
      setSound({ sound: new Audio(en) });
    }
    if (langauge == "kn") {
      setSound({ sound: new Audio(kn) });
    }
    if (langauge == "hi") {
      setSound({ sound: new Audio(hi) });
    }
    // if (localStorage.getItem("mute")) {
    //   setMute(localStorage.getItem("mute"));
    // console.log(localStorage.getItem("mute"));
    // handleSound(!localStorage.getItem("mute"));
    // }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, []);

  useEffect(() => {
    let ss = sound.sound;
    if (typeof ss === "object") {
      ss.play();
      console.log(ss);
    }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, [sound]);

  const handleSound = (unmute) => {
    if (sound.sound) {
      let ss = sound.sound;
      if (typeof ss === "object") {
        console.log("aayaya", unmute);
        if (unmute) {
          ss.volume = 1;
        } else {
          ss.volume = 0;
        }
      }
    }
  };

  const { t, i18n } = useTranslation();
  const [beneficiaries, setBeneficiaries] = useState(undefined);
  const history = useHistory();
  const [qr, setQr] = useState(undefined);
  const [congo, setCongo] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const generateQrCode = async (value) => {
    try {
      const response = await QRCode.toDataURL(value);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  const modal = () => {
    sessionStorage.setItem("pdf_data", JSON.stringify(beneficiaries));
    setShowModal(true);
  };
  useEffect(async () => {
    try {
      if (localStorage.getItem("user_details")) {
        if (location.state.response) {
          let data = JSON.parse(location.state.response).data;
          console.log(data);
          let qr = "";
          for (var i = 0; i < data.length; i++) {
            qr += `${data[i].token}\n`;
          }
          var x = await generateQrCode(qr).then((e) => {
            return e;
          });
          setQr(x);
          setBeneficiaries(data);
        }
        localStorage.setItem("qr", JSON.stringify(x));
        setCongo(true);
        console.log(beneficiaries, "bene");
      } else {
        history.push("/home");
      }
    } catch (e) {
      history.push("/home");
    }
  }, []);
  return (
    <>
      <div
        className="w-full flex justify-center pt-4"
        style={{
          backgroundImage:
            "https://img.onmanorama.com/content/dam/mm/en/lifestyle/health/images/2021/4/22/vaccination-shutterstock-c.jpg",
        }}
      >
        <div className="w-full max-w-2xl mt-16">
          <div className="w-full mx-auto max-w-xl  inline-flex">
            <div className="mx-8 flex-1 text-gray-700 text-4xl">
              {t("components.token.title")}
            </div>
            <div className="w-full flex-1 flex flex-col item-center inline text-gray-500 text-2xl ">
              <button
                type="button"
                className="focus:outline-none"
                onClick={modal}
              >
                <i className="fa fa-download"></i>
              </button>
            </div>
          </div>
          <div className="mb-auto">
            {beneficiaries &&
              beneficiaries.map((items) => (
                <div
                  key={items.beneficiary_reference_id}
                  className="threejs w-full max-w-2xl bg-gray-100 shadow-lg rounded mx-auto px-8 pt-6 pb-8 mb-4 text-xl"
                >
                  <h3 className="text-2xl text-justify ">
                    {t("components.token.field1")}: {items.name}
                  </h3>
                  <h3 className="text-2xl text-justify">
                    {t("components.token.field2")}:{" "}
                    {items.beneficiary_reference_id}
                  </h3>
                  <h3 className="text-2xl text-justify">
                    {t("components.token.field3")}: {items.mobile_number}
                  </h3>
                  <h3 className="text-2xl text-justify">
                    {t("components.token.field4")}: {items.token.substr(0, 2)}-
                    {items.token.substr(2, 2)}-{items.token.substr(4, 4)}
                  </h3>
                  <h3 className="text-2xl text-justify">
                    {t("components.token.field5")}: {items.vaccine}
                  </h3>
                  <h3 className="text-2xl text-justify tokenn">
                    {t("components.token.field6")}:{items.token.substr(9)}
                  </h3>
                  {qr ? (
                    <div className="">
                      <img src={qr} alt="img" />
                    </div>
                  ) : null}
                </div>
              ))}
          </div>

          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-xl">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-100 outline-none focus:outline-none">
                    <div className="flex items-start justify-between p-5 border-b border-solid border-grayGray-200 rounded-t">
                      <p className="font-semibold">
                        {t("components.token.down")}
                      </p>
                      <button
                        className="focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="ml-2">x</span>
                      </button>
                    </div>
                    <div className="relative p-6 flex-auto">
                      <GenericPdfDownloader
                        downloadFileName="TokenDetails"
                        rootElementId="root"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
          {congo ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  <div className="border-0 rounded-lg shadow-lg relative w-full bg-yellow-50 outline-none focus:outline-none grid place-items-center">
                    <div className=" text-green-300 active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                      <p className="font-semibold text-3xl">
                        {t("components.token.success")}
                      </p>
                      <hr />
                    </div>
                    <div className="relative p-6 flex-auto text-2xl text-center">
                      <p className=" text-lg mb-1 mx-2">
                        {t("components.token.message")}
                      </p>

                      <button
                        className="w-full bg-green-400 text-gray-100 active:bg-pink-600 font-bold uppercase text-lg px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={() => setCongo(false)}
                      >
                        <span className="ml-2">{t("components.token.ok")}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      </div>
      <div className="absolute left-0 top-0 mr-16 px-2">
        {mute ? (
          <GiSpeakerOff
            className="text-4xl"
            onClick={() => {
              setMute(false);
              handleSound(mute);
            }}
          />
        ) : (
          <GiSpeaker
            className="text-4xl"
            onClick={() => {
              setMute(true);
              handleSound(mute);
            }}
          />
        )}
      </div>
    </>
  );
}

export default Token;
