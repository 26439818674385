import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useTranslation } from "react-i18next";
import "./token.css";
import QRCode from "qrcode";
const GenericPdfDownloader = ({ rootElementId, downloadFileName }) => {
  const { t, i18n } = useTranslation();
  const [data, Setdata] = useState(undefined);
  const [qr, setQr] = useState(undefined);
  const generateQrCode = async (value) => {
    try {
      const response = await QRCode.toDataURL(value);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(async () => {
    let x = JSON.parse(sessionStorage.getItem("pdf_data"));
    let qr = "";
    for (var i = 0; i < x.length; i++) {
      qr += `${x[i].token}\n`;
    }
    var xy = await generateQrCode(qr).then((e) => {
      return e;
    });
    setQr(xy);
    Setdata(x);
  }, []);
  const downloadPdfDocument = () => {
    const input = document.getElementById("token");
    document.getElementById("token").removeAttribute("hidden");
    console.log(input);
    html2canvas(input, {
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("token").style.display = "block";
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      document.getElementById("token").setAttribute("hidden", true);
      // console.log(imgData);
      // input.style.display = "none";
      const pdf = new jsPDF("p", "px", "a4");
      pdf.addImage(imgData, "JPEG", 60, 10);

      pdf.save(`${downloadFileName}.pdf`);
      // const imgData = canvas.toDataURL("image/png");
      // const pdf = new jsPDF("p", "px", "a4");
      // var width = pdf.internal.pageSize.getWidth();
      // var height = pdf.internal.pageSize.getHeight();

      // pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      // pdf.save(`${downloadFileName}.pdf`);
    });
  };

  return (
    <>
      <div id="token" hidden>
        <div className="grid place-items-center">
          <h1 className="text-2xl text-gray-500">
            {t("components.token.title")}
          </h1>
          <br />
          <div style={{ float: "right" }}>
            <a href={qr} download>
              <img className="inline" src={qr} alt="img" />
            </a>
          </div>
          {data &&
            data.map((items) => (
              <div
                key={items.beneficiary_reference_id}
                className="threejs w-full rounded mx-auto px-8 pt-6 pb-8 text-xl border border-green-900 bg-gray-500 text-gray-100"
              >
                <h1>
                  {t("components.token.field1")}: {items.name}
                </h1>
                <h2>
                  {t("components.token.field2")}:{" "}
                  {items.beneficiary_reference_id}
                </h2>
                <h2 className="tokenn">
                  {t("components.token.field6")}:{items.token.substr(9)}
                </h2>
                <h2>
                  {t("components.token.field4")}: {items.token.substr(0, 2)}-
                  {items.token.substr(2, 2)}-{items.token.substr(4, 4)}
                </h2>
                <h2>
                  {t("components.token.field5")}: {items.vaccine}
                </h2>
              </div>
            ))}
        </div>
      </div>
      <button
        onClick={downloadPdfDocument}
        className="bg-gray-500 text-gray-100 active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      >
        <i className="fa fa-download"></i>
        {t("components.token.down")}
      </button>
    </>
  );
};

export default GenericPdfDownloader;
