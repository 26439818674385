import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdOutlineArrowBackIos } from "react-icons/md";
function Back() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeChange = () => {
    history.goBack();
  };
  return (
    <div>
      <a
        className="text-lg md:text-2xl text-gray-500 font-semibold hover:text-blue-500 transition duration-300"
        onClick={routeChange}
      >
        <div className="flex">
          <MdOutlineArrowBackIos />
          <span className="text-xs md:text-xl text-gray-500">Back</span>
        </div>
      </a>
    </div>
  );
}

export default Back;
