import React from "react";

function NotFound() {
  return (
    <div>
      <p className="text-3xl font-bold text-red-900 ml-32">
        500 SERVER ERROR, CONTACT ADMINISTRATOR!!!!
      </p>
    </div>
  );
}

export default NotFound;
