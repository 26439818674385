import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18next";
import Footer from "./components/Footer";

ReactDOM.render(
  // <React.StrictMode>
  [
    <Suspense fallback="loading">
      <App />
    </Suspense>,
    <Footer />,
  ],
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
