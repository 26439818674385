import iitdh from "../logos/iitdh-logo-original-3.png";
import kims from "../logos/KIMS (1).jpeg";
const Footer = () => (
  <footer
    className="footer w-full shadow-lg
          bg-gray-100 pt-4"
  >
    {/* <div className="flex justify-around">
      <div>
        <img style={{ width: "100px" }} src={iitdh} alt="iitdh" />
      </div>
      <div>
        <img style={{ width: "60px" }} src={kims} alt="kims" />
      </div>
    </div>
    <hr /> */}
    <div className="flex justify-center">
      <p>
        <i class="fa fa-copyright"></i> Copyright 2021{" "}
        <a href="home.php">@IITDH</a>. All rights are reserved
      </p>
    </div>
  </footer>
);

export default Footer;
