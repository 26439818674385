import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Home from "./components/home";
import Redirect from "./components/redirect";
import Dashboard from "./components/Dashboard";
import UserVerification from "./components/userVerification";
import Offline from "./components/Offline";
import Token from "./components/Token";
import Logout from "./components/Logout";
import NotFound from "./components/NotFound";
import Back from "./components/Back";
import Staff from "./components/Staff";
import StaffHome from "./components/StaffHome";
import Blank from "./components/Blank";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import TokenDashboard from "./components/TokenDashboard";
import DownloadToken from "./components/DownloadToken";
import Tokend from "./components/Tokend";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FaLanguage } from "react-icons/fa";
import { GrRefresh } from "react-icons/gr";

import "./App.css";
import iitdh from "./logos/iitdh-logo-original-3.png";
import kims from "./logos/KIMS (1).jpeg";
import kimstitle from "./logos/cooltext397172893144763.png";

function App() {
  const { t, i18n } = useTranslation();
  const [drop, setDrop] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [login, setLogin] = useState("");
  const history = useHistory();

  useEffect(() => {
    setLogin(localStorage.getItem("staff_token"));
    forceUpdate();
  }, [history]);
  return (
    <div className="font content">
      <Router>
        <div
          className="w-full shadow-lg
          bg-gray-100 rounded pb-3"
        >
          <div className="flex justify-between px-2">
            <img
              src={iitdh}
              className="ml-4 md:ml-16 max-w-sm max-h-7 md:max-h-20 mt-1"
              alt="iitdh"
            />
            <div className="flex">
              <div className="mt-4 pt-2">
                <Link
                  to="/home"
                  className=" text-gray-500 text-xs md:text-2xl font-semibold hover:text-white transition duration-300 "
                >
                  <GrRefresh />
                </Link>
              </div>
              <div className="md:ml-4 md:mt-4">
                {/* <div class="py-4 px-2 text-gray-500 text-xl font-semibold hover:text-white transition duration-300"> */}
                <FaLanguage
                  title="Choose your langauge"
                  className="text-blue-500 text-2xl mr-2 md:text-3xl md:text-5xl ml-1"
                  onClick={() => {
                    console.log("hi");
                    if (drop) {
                      setDrop(false);
                    } else {
                      setDrop(true);
                    }
                  }}
                />
                {drop && (
                  <div class="absolute py-2 mt-2 bg-white bg-gray-100 rounded-md shadow-xl w-44">
                    <button
                      className="w-full bg-green-400 text-white active:bg-pink-600 font-bold uppercase text-xs px-3 py-2 rounded shadow hover:shadow-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      onClick={() => {
                        setDrop(false);
                        localStorage.setItem("language", "en");
                        i18n.changeLanguage("en");
                      }}
                    >
                      <span className="ml-2">English</span>
                    </button>
                    <button
                      className="w-full bg-green-400 text-white active:bg-pink-600 font-bold uppercase text-xs px-3 py-2 rounded shadow hover:shadow-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      onClick={() => {
                        setDrop(false);
                        localStorage.setItem("language", "hi");
                        i18n.changeLanguage("hi");
                      }}
                    >
                      <span className="ml-2">हिंदी</span>
                    </button>
                    <button
                      className="w-full bg-green-400 text-white active:bg-pink-600 font-bold uppercase text-xs px-3 py-2 rounded shadow hover:shadow-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      onClick={() => {
                        setDrop(false);
                        localStorage.setItem("language", "kn");
                        i18n.changeLanguage("kn");
                      }}
                    >
                      <span className="ml-2">ಕನ್ನಡ</span>
                    </button>
                  </div>
                )}
                {/* </div> */}
              </div>
              <div className="float-left md:flex items-center space-x-5">
                <span className="lining font-semibold text-gray-500 text:lg md:text-3xl">
                  {t("app.Title")}
                </span>
              </div>
              {/* <img
                src={kimstitle}
                className="md:mr-4 max-w-sm md:max-w-xl max-h-7 md:max-h-32 h-20 mt-1 md:mt-0 md:mt-0 md:max-h-12"
                alt="kimstitle"
              /> */}
            </div>
            <img
              src={kims}
              className="md:mr-4 max-w-lg max-h-7 md:max-h-20 mt-1"
              alt="kims"
            />
          </div>
          <nav className="pb-2 px-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="menu-button"
              className="h-6 w-6 cursor-pointer md:hidden block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={() => {
                let x = document.getElementById("menu");
                x.classList.toggle("hidden");
              }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <div
              class="
                hidden
              text-base text-gray-700
              md:flex 
              md:justify-between
              md:pt-0"
              id="menu"
            >
              {/* <div>
              <a class="md:p-4 py-2 block hover:text-purple-400" href="#">
                Features
              </a>
            </div> */}
              {/* <div className="flex space-x-7">
                <div className="md:flex items-center space-x-5"> */}
              <div>
                <Back />
              </div>
              <div className="md:flex">
                <div>
                  <Link
                    to="/home"
                    className="py-4 px-2 text-gray-500 text-xs md:text-xl font-semibold hover:text-white transition duration-300"
                  >
                    {t("app.home")}
                  </Link>
                </div>
                <div>
                  <Link
                    to="/staff"
                    className="py-4 px-2 text-gray-500 text-xs md:text-xl font-semibold hover:text-white transition duration-300"
                    title="Please login into staff from here"
                  >
                    {t("app.staff")}
                  </Link>
                </div>
                <div>
                  <Link
                    to="/offline"
                    className="py-4 px-2 text-gray-500 text-xs md:text-xl font-semibold hover:text-white transition duration-300"
                    title="Please generate Tokens from here if online was not working"
                  >
                    {t("app.OfflineToken")}
                  </Link>
                </div>
                <div>
                  <Link
                    to="/download"
                    className="py-4 px-2 text-gray-500 text-xs md:text-xl font-semibold hover:text-white transition duration-300"
                    title="Please generate Tokens from here if online was not working"
                  >
                    {t("app.DownloadToken")}
                  </Link>
                </div>
              </div>
              <div className="mt-1">
                <a
                  onClick={() => {
                    Logout();
                  }}
                  className="py-1 md:px-1 mx-2 text-xs md:text-lg text-white font-semibold md:font-medium md:text-white md:bg-gray-500 rounded hover:bg-gray-400 transition duration-300"
                >
                  {t("app.Logout")}
                </a>
              </div>
            </div>
          </nav>
        </div>
        {/* <img
          src={iitdh}
          className="fixed max-w-2xl max-h-56"
          style={{ top: "160px", left: "40px" }}
          alt="iitdh"
        />
        <img
          src={kims}
          className="fixed"
          style={{ width: "200px", top: "160px", right: "60px" }}
          alt="kims"
        /> */}
        <div className="flex">
          <nav className="py-2 px-4 bg-gray-100 w-1/7 fit viewport">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="menu-button"
              className="h-6 w-6 cursor-pointer block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={() => {
                let x = document.getElementById("menuu");
                x.classList.toggle("hidden");
              }}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <div
              class="
                hidden
              text-base text-gray-700
              "
              id="menuu"
            >
              <div className="flex flex-col flex-grow h-auto justify-between mt-4">
                <div>
                  <Link
                    to="/home"
                    className=" text-gray-500 text-xs md:text-xl font-semibold hover:text-white transition duration-300"
                  >
                    {t("app.home")}
                  </Link>
                </div>
                <div>
                  <Link
                    to="/staff"
                    className=" text-gray-500 text-xs md:text-xl font-semibold hover:text-white transition duration-300"
                    title="Please login into staff from here"
                  >
                    {t("app.staff")}
                  </Link>
                </div>
                <div>
                  <Link
                    to="/offline"
                    className=" text-gray-500 text-xs md:text-xl font-semibold hover:text-white transition duration-300"
                    title="Please generate Tokens from here if online was not working"
                  >
                    {t("app.OfflineToken")}
                  </Link>
                </div>
                <div>
                  <Link
                    to="/download"
                    className="text-gray-500 text-xs md:text-xl font-semibold hover:text-white transition duration-300"
                    title="Please generate Tokens from here if online was not working"
                  >
                    {t("app.DownloadToken")}
                  </Link>
                </div>
              </div>
            </div>
          </nav>
          <Switch>
            <Route exact path="/" component={Blank} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/staff" component={Staff} />
            <Route exact path="/staffhome" component={StaffHome} />
            <Route exact path="/redirect" component={Redirect} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/user" component={UserVerification} />
            <Route exact path="/token" component={Token} />
            <Route exact path="/offline" component={Offline} />
            <Route exact path="/tokendashboard" component={TokenDashboard} />
            <Route exact path="/download" component={DownloadToken} />
            <Route exact path="/dtoken" component={Tokend} />
            <Route path="*" component={NotFound}></Route>
          </Switch>
        </div>
      </Router>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default App;
