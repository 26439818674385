import React, { useEffect, useState } from "react";
import { getOTP, verify_otp } from "../request/helper";
import { useHistory } from "react-router-dom";
import Slotdata from "./Slotdata";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FcPhone } from "react-icons/fc";
import { RiLockPasswordFill } from "react-icons/ri";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import en from "../audio/home_en.mp3";
import hi from "../audio/home_hi.mp3";
import kn from "../audio/home_kn.mp3";

import home from "../pictures/home.gif";

import { BsFillPatchCheckFill } from "react-icons/bs";
function Home() {
  const { t, i18n } = useTranslation();
  const [sound, setSound] = useState({ sound: "" });
  let get = t("components.home.get");
  let resend = t("components.home.resend");
  const [otpwarning, setOtpwarning] = useState(false);
  const history = useHistory();
  const [otp, setOtp] = useState(get);
  const [phonewarn, setPhonewarn] = useState(false);
  const [buttonnow, setButtonnow] = useState(false);
  const [clock, setClock] = useState(null);
  const [mute, setMute] = useState(false);
  const [userdetails, setUserdetails] = useState({
    phone_number: "",
    txnId: "",
    otp: "",
    token: "",
    isNewAccount: "",
  });
  const handleChange = (e) => {
    setPhonewarn(false);
    setUserdetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setOtpwarning(false);
  };
  const handleOTP = async (e) => {
    e.preventDefault();
    if (userdetails.phone_number.length !== 10) {
      setPhonewarn(true);
    } else {
      setClock(180);
      setButtonnow(true);
      setOtp(resend);
      setOtpwarning(true);
      let txnID = await getOTP(userdetails.phone_number);
      setUserdetails((prev) => ({ ...prev, txnId: txnID }));
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (otpwarning) {
      toast.warning("Please Enter Otp");
    } else {
      try {
        var response = await verify_otp(userdetails);
        if (response.status == 200) {
          if (response.data.error) {
            toast.error("Invalid Otp please write correct Otp");
          } else {
            setUserdetails((prev) => ({
              ...prev,
              token: response.data.token,
              isNewAccount: response.data.isNewAccount,
            }));
            if (response.data.isNewAccount === "Y") {
              history.push("/redirect");
            } else {
              history.push("/dashboard");
            }
          }
        }
      } catch (err) {
        toast.error("Server busy right now please use offline service!");
        history.push("/offline");
      }
    }
  };
  useEffect(() => {
    localStorage.setItem("user_details", JSON.stringify(userdetails));
    // console.log(userdetails);
  }, [userdetails]);
  useEffect(() => {
    const timer = clock > 0 && setInterval(() => setClock(clock - 1), 1000);
    if (timer === false) {
      setButtonnow(false);
    }
    return () => clearInterval(timer);
  }, [clock]);
  useEffect(() => {
    let langauge = localStorage.getItem("language");
    // console.log(langauge);
    if (langauge == "en") {
      setSound({ sound: new Audio(en) });
    }
    if (langauge == "kn") {
      setSound({ sound: new Audio(kn) });
    }
    if (langauge == "hi") {
      setSound({ sound: new Audio(hi) });
    }
    // if (localStorage.getItem("mute")) {
    //   setMute(localStorage.getItem("mute"));
    // console.log(localStorage.getItem("mute"));
    // handleSound(!localStorage.getItem("mute"));
    // }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("mute", mute);
  }, [mute]);
  useEffect(() => {
    let ss = sound.sound;
    if (typeof ss === "object") {
      ss.play();
      console.log(ss);
    }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, [sound]);

  const handleSound = (unmute) => {
    if (sound.sound) {
      let ss = sound.sound;
      if (typeof ss === "object") {
        console.log("aayaya", unmute);
        if (unmute) {
          ss.volume = 1;
        } else {
          ss.volume = 0;
        }
      }
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="flex justify-center py-4 px-2 ">
          <span className="lining font-semibold text-gray-500 text:lg md:text-3xl">
            {t("app.Title")}
          </span>
        </div>
        <div
          className="grid grid-cols-3 divide-x-2
                    divide-blue-500"
        >
          {/* <div className="w-6/12 sm:w-4/12 px-4">
          <div className="flex justify-center mt-2">
            <span className="text-gray-900 font-black text-lg md:text-2xl">
              {t("app.generate")}
            </span>
          </div>
          <img
            src={home}
            classname="shadow rounded-full max-w-full h-auto align-middle border-none"
            alt="home"
          />
        </div> */}
          <div className="w-full  flex flex-wrap justify-center">
            <div>
              <div className="flex justify-center mt-2">
                <span className="text-gray-900 font-black text-lg md:text-2xl">
                  {t("app.generate")}
                </span>
              </div>
              <div className="mx-auto">
                <img
                  src={home}
                  alt="..."
                  className="shadow-lg rounded max-w-full h-auto align-middle border-none"
                />
              </div>
            </div>
          </div>
          <div className="w-full col-span-2 pl-28">
            <div className="w-full py-1 mt-0 max-w-3xl md:mt-16">
              <form className="bg-gray-100 shadow-lg rounded px-4 pt-4 pb-8 mb-4">
                <div className="flex justify-center">
                  <h1 className="text-gray-900 font-black text-lg md:text-2xl">
                    {t("components.home.title")}
                  </h1>
                </div>
                <div className="mb-4 mt-4">
                  <p className="block text-gray-700 text-sm font-bold mb-2 required lining">
                    <FcPhone />
                    {t("components.home.input1.label")}
                  </p>
                  <br />
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
                    type="number"
                    name="phone_number"
                    value={userdetails.phone_number}
                    placeholder={t("components.home.input1.placeholder")}
                    onChange={handleChange}
                    required
                  />
                  {phonewarn && (
                    <p className="text-red-500 text-xs italic">
                      {t("components.home.phonewarn")}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <button
                    className={`mb-2 lining ${
                      buttonnow
                        ? "bg-gray-300"
                        : "bg-gray-400 hover:bg-gray-700"
                    } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                    onClick={handleOTP}
                    disabled={buttonnow}
                  >
                    {t("components.home.get")}
                  </button>
                  {clock > 0 && clock}
                </div>
                <div className="mb-4">
                  <label className="lining required block text-gray-700 text-sm font-bold mb-2">
                    <RiLockPasswordFill />
                    {t("components.home.input2.label")}
                  </label>
                  <br />
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
                    type="text"
                    placeholder={t("components.home.input2.placeholder")}
                    value={userdetails.otp}
                    name="otp"
                    onChange={handleChange}
                  />
                  {otpwarning && (
                    <p className="text-red-500 text-xs italic">
                      {t("components.home.otpwarn")}
                    </p>
                  )}
                </div>
                <button
                  className="lining bg-gray-400 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  // disabled={otpwarning}
                  onClick={handlesubmit}
                >
                  <BsFillPatchCheckFill />
                  {t("components.home.confirm")}
                </button>
              </form>
            </div>
            <Slotdata />
          </div>
          <div className="absolute left-0 top-0 mr-16">
            {mute ? (
              <GiSpeakerOff
                className="text-2xl md:text-4xl"
                onClick={() => {
                  setMute(false);
                  handleSound(mute);
                }}
              />
            ) : (
              <GiSpeaker
                className="text-2xl md:text-4xl"
                onClick={() => {
                  setMute(true);
                  handleSound(mute);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
