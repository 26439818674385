import { useHistory } from "react-router-dom";
import { getStafftoken } from "../request/helper";
import React, { useState, useEffect } from "react";
import { FcPhone } from "react-icons/fc";
import { RiLockPasswordFill } from "react-icons/ri";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";

import en from "../audio/staff_en.mp3";
import hi from "../audio/staff_hi.mp3";
import kn from "../audio/staff_kn.mp3";
function Staff() {
  const { t, i18n } = useTranslation();
  const [sound, setSound] = useState({ sound: "" });
  const [mute, setMute] = useState(false);

  useEffect(() => {
    let langauge = localStorage.getItem("language");
    // console.log(langauge);
    if (langauge == "en") {
      setSound({ sound: new Audio(en) });
    }
    if (langauge == "kn") {
      setSound({ sound: new Audio(kn) });
    }
    if (langauge == "hi") {
      setSound({ sound: new Audio(hi) });
    }
    // if (localStorage.getItem("mute")) {
    //   setMute(localStorage.getItem("mute"));
    // console.log(localStorage.getItem("mute"));
    // handleSound(!localStorage.getItem("mute"));
    // }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, []);

  useEffect(() => {
    let ss = sound.sound;
    if (typeof ss === "object") {
      ss.play();
      console.log(ss);
    }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, [sound]);

  const handleSound = (unmute) => {
    if (sound.sound) {
      let ss = sound.sound;
      if (typeof ss === "object") {
        console.log("aayaya", unmute);
        if (unmute) {
          ss.volume = 1;
        } else {
          ss.volume = 0;
        }
      }
    }
  };

  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    let response = await getStafftoken(data);
    if (response) {
      console.log(response);
      localStorage.setItem("staff_token", JSON.stringify(response.data.token));
      history.push("/staffhome");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("staff_token")) {
      history.push("/staffhome");
    }
  }, []);
  return (
    <div className="w-full">
      <div className="flex-none lg:flex justify-center pt-4 ">
        <div className="w-full max-w-2xl mt-16 ml-4">
          <form
            onSubmit={handleSubmit}
            className="bg-gray-100 shadow-lg rounded px-8 pt-6 pb-8 mb-4"
          >
            <div className="flex justify-center pt-4">
              <h1 className="text-gray-700 text-2xl">
                KIMS {t("components.staff.title")}
              </h1>
            </div>
            <label className="lining required block text-gray-700 text-sm font-bold mb-2">
              <FcPhone />
              {t("components.staff.username")}
            </label>
            <br />
            <input
              type="text"
              value={data.username}
              onChange={(e) => {
                setData({ ...data, username: e.target.value });
              }}
              className="shadow appearance-none border max-w-lg rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
            />
            <br />
            <label className="lining required block text-gray-700 text-sm font-bold mb-2">
              <RiLockPasswordFill /> {t("components.staff.pass")}
            </label>
            <br />
            <input
              type="password"
              value={data.password}
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
              }}
              className="shadow appearance-none border max-w-lg rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
            />
            <br />
            <div className="flex justify-center pt-4">
              <button className="lining bg-gray-500 mt-3 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <BsFillPatchCheckFill /> {t("components.staff.verify")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="absolute left-0 top-0 mr-16 px-2">
        {mute ? (
          <GiSpeakerOff
            className="text-4xl"
            onClick={() => {
              setMute(false);
              handleSound(mute);
            }}
          />
        ) : (
          <GiSpeaker
            className="text-4xl"
            onClick={() => {
              setMute(true);
              handleSound(mute);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Staff;
