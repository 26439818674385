import React, { useEffect, useState } from "react";
import { getBeneficiaries, getToken } from "../request/helper";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GenericPdfDownloader from "./GenericPdfDownloader";
import en from "../audio/dashboard_en.mp3";
import hi from "../audio/dashboard_hi.mp3";
import kn from "../audio/dashboard_kn.mp3";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { BsFillPatchQuestionFill, BsFillPatchCheckFill } from "react-icons/bs";
function Dashboard() {
  const { t, i18n } = useTranslation();
  const [sound, setSound] = useState({ sound: "" });
  const [mute, setMute] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState(undefined);
  const [tokendata, setTokendata] = useState(undefined);
  const [code, setCode] = useState("");
  const [msg, setMsg] = useState("");
  const [verified, setVerified] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [alertModal, setAlertModal] = React.useState(false);
  const [qr, setQr] = useState(undefined);
  const history = useHistory();
  const modal = (id) => {
    console.log(id);
    var y = [];
    for (let i = 0; i < tokendata.length; i++) {
      if (tokendata[i].beneficiary_reference_id === id) {
        y.push(tokendata[i]);
        break;
      }
    }
    if (y.length !== 0) {
      sessionStorage.setItem("pdf_data", JSON.stringify(y));
      setShowModal(true);
    } else {
      setAlertModal(true);
    }
  };
  useEffect(() => {
    async function fetch_beneficary() {
      let user_details = JSON.parse(localStorage.getItem("user_details"));
      let response = await getBeneficiaries(user_details);
      setBeneficiaries(response.data.beneficiaries);
      localStorage.setItem(
        "benificiaries",
        JSON.stringify(response.data.beneficiaries)
      );
      sessionStorage.setItem(
        "benificiaries",
        JSON.stringify(response.data.beneficiaries)
      );
    }
    async function fetch_tokens() {
      let user_details = JSON.parse(localStorage.getItem("user_details"));
      let response = await getToken(user_details);
      setTokendata(JSON.parse(response.data));
    }
    fetch_tokens();
    fetch_beneficary();
    let yy = JSON.parse(sessionStorage.getItem("verified"));
    if (yy) {
      setVerified(yy);
    }
    let yt = JSON.parse(localStorage.getItem("qr"));
    setQr(yt);
  }, []);
  const goforward = () => {
    history.push("/user");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    for (var i = 0; i < beneficiaries.length; i++) {
      if (code === beneficiaries[i].beneficiary_reference_id.substr(-4, 4)) {
        setMsg("User Verified Succesfully");
        sessionStorage.setItem("verified", JSON.stringify(true));
        history.push("/user");
      } else {
        setMsg("Invalid secret code....");
      }
    }
  };
  useEffect(() => {
    let langauge = localStorage.getItem("language");
    // console.log(langauge);
    if (langauge == "en") {
      setSound({ sound: new Audio(en) });
    }
    if (langauge == "kn") {
      setSound({ sound: new Audio(kn) });
    }
    if (langauge == "hi") {
      setSound({ sound: new Audio(hi) });
    }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, []);
  useEffect(() => {
    let ss = sound.sound;
    if (typeof ss === "object") {
      ss.play();
      console.log(ss);
    }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, [sound]);

  const handleSound = (unmute) => {
    if (sound.sound) {
      let ss = sound.sound;
      if (typeof ss === "object") {
        console.log("aayaya", unmute);
        if (unmute) {
          ss.volume = 1;
        } else {
          ss.volume = 0;
        }
      }
    }
  };
  return (
    <div className="w-full max-w-2xl mx-auto px-4 mt-16 ">
      {beneficiaries &&
        beneficiaries.map((items) => (
          <div
            className="bg-gray-100 shadow-lg px-2 mb-4"
            key={items.beneficiary_reference_id}
          >
            <div className="inline-flex ">
              <div className="flex flex-col justify-center text-gray-700 text-2xl">
                {items.name}
              </div>
              <div className="w-full flex-1 flex flex-col item-center inline text-lg ml-4">
                <button
                  type="button"
                  className="focus:outline-none"
                  onClick={() => modal(items.beneficiary_reference_id)}
                >
                  <i className="fa fa-download"></i>
                </button>
              </div>
            </div>
            <div className="flex flex-row">
              <p className="text-xl"> {t("components.dashboard.yob")}:</p>
              <p className="text-red-700 text-xl mx-4">{items.birth_year}</p>
            </div>
            <div className="flex flex-row">
              <p className="text-xl">{t("components.dashboard.idtype")}:</p>
              <p className="text-red-700 text-xl mx-4">{items.photo_id_type}</p>
            </div>
            <div className="flex flex-row">
              <p className="text-xl">{t("components.dashboard.id")}:</p>
              <p className="text-red-700 text-xl mx-4">
                {items.photo_id_number}
              </p>
            </div>
            {items.vaccine ? (
              <div className="flex flex-row">
                <p className="text-xl">{t("components.dashboard.vaccine")}:</p>
                <p className="text-red-700 text-xl mx-4">{items.vaccine}</p>
              </div>
            ) : (
              [
                items.dose1_date ? (
                  <div className="flex flex-row">
                    <p className="text-xl">
                      {t("components.dashboard.dose1")}:{" "}
                    </p>

                    <p className="text-red-700 text-xl mx-4">
                      {items.dose1_date}
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-row">
                    <p className="text-xl">
                      {t("components.dashboard.dose1")}:
                    </p>
                    <p className="text-red-700 text-xl mx-4">
                      {t("components.dashboard.appointment")}
                    </p>
                  </div>
                ),
                items.dose2_date ? (
                  <div className="flex flex-row">
                    <p className="text-xl">
                      {t("components.dashboard.dose2")}:{" "}
                    </p>

                    <p className="text-red-700 text-xl mx-4">
                      {items.dose2_date}
                    </p>
                  </div>
                ) : (
                  <div className="flex flex-row">
                    <p className="text-xl">
                      {t("components.dashboard.dose2")}:
                    </p>
                    <p className="text-red-700 text-xl mx-4">
                      {t("components.dashboard.appointment")}
                    </p>
                  </div>
                ),
              ]
            )}
          </div>
        ))}
      <div>
        {/* {qr ? (
          <div className="flex flex-col item-center bg-gray-100 rounded mx-auto px-8 mb-4">
            <a className="inline mx-auto mt-16 pt-4" href={qr} download>
              <i className="fa fa-download"></i>
              <img className="inline" src={qr} alt="img" />
            </a>
          </div>
        ) : null} */}
        <h1 className="text-gray-700 ">
          {t("components.dashboard.secret.label")}
          <a
            href="https://selfregistration.cowin.gov.in/"
            target="_blank"
            className="lining bg-gray-500 text-lg px-2 py-1 text-gray-100 font-bold ml-24 rounded focus:outline-none focus:shadow-outline"
          >
            <BsFillPatchQuestionFill />
            {t("components.dashboard.search")}
          </a>
        </h1>
        {verified ? (
          <p>
            {t("components.dashboard.already")}
            <button
              className="bg-gray-500 hover:bg-gray-700 text-gray-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={goforward}
            >
              {t("components.dashboard.register")}
            </button>
          </p>
        ) : (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder={t("components.dashboard.secret.placeholder")}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
              onChange={(e) => {
                if (e.target.value.length <= 4) {
                  setCode(e.target.value);
                }
              }}
              value={code}
            />
            <h1 className="text-red-500">{msg}</h1>
            <button
              type="submit"
              className="lining bg-gray-500 hover:bg-gray-700 text-gray-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              <BsFillPatchCheckFill />
              {t("components.dashboard.verify")}
            </button>
          </form>
        )}
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-100 outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-grayGray-200 rounded-t">
                  <p className="font-semibold">
                    {t("components.dashboard.download")}
                  </p>
                  <button
                    className="focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="ml-2">x</span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <GenericPdfDownloader
                    downloadFileName="TokenDetails"
                    rootElementId="root"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {alertModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-100 outline-none focus:outline-none">
                <div className=" p-5 border-b border-solid border-grayGray-200 rounded-t grid place-items-center">
                  {/* <p className="font-semibold">Download Pdf</p> */}
                  <button
                    className="focus:outline-none "
                    onClick={() => setAlertModal(false)}
                  >
                    <span className="mx-auto">x</span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <div className="bg-gray-500 text-gray-100 active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                    {t("components.dashboard.generate")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div className="absolute left-0 top-0 mr-16 px-2">
        {mute ? (
          <GiSpeakerOff
            className="text-4xl"
            onClick={() => {
              setMute(false);
              handleSound(mute);
            }}
          />
        ) : (
          <GiSpeaker
            className="text-4xl"
            onClick={() => {
              setMute(true);
              handleSound(mute);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Dashboard;
