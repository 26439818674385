import { useState, useEffect } from "react";
import { addSlots } from "../request/helper";
import { useHistory } from "react-router-dom";
import TokenDashboard from "./TokenDashboard";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Slotdata from "./Slotdata";
import { toast } from "react-toastify";
import { FcLeftUp2 } from "react-icons/fc";
import { BsFillPatchCheckFill, BsCalendarDate } from "react-icons/bs";
import { AiFillMedicineBox } from "react-icons/ai";
import { GiOverdose } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import "./token.css";
import en from "../audio/slots_en.mp3";
import hi from "../audio/slots_hi.mp3";
import kn from "../audio/slots_kn.mp3";
function StaffHome() {
  const { t, i18n } = useTranslation();
  const [sound, setSound] = useState({ sound: "" });
  const [mute, setMute] = useState(false);
  useEffect(() => {
    let langauge = localStorage.getItem("language");
    // console.log(langauge);
    if (langauge == "en") {
      setSound({ sound: new Audio(en) });
    }
    if (langauge == "kn") {
      setSound({ sound: new Audio(kn) });
    }
    if (langauge == "hi") {
      setSound({ sound: new Audio(hi) });
    }
    // if (localStorage.getItem("mute")) {
    //   setMute(localStorage.getItem("mute"));
    // console.log(localStorage.getItem("mute"));
    // handleSound(!localStorage.getItem("mute"));
    // }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, []);

  useEffect(() => {
    let ss = sound.sound;
    if (typeof ss === "object") {
      ss.play();
      console.log(ss);
    }
    return () => {
      let ss = sound.sound;
      if (typeof ss === "object") {
        ss.pause();
      }
    };
  }, [sound]);

  const handleSound = (unmute) => {
    if (sound.sound) {
      let ss = sound.sound;
      if (typeof ss === "object") {
        console.log("aayaya", unmute);
        if (unmute) {
          ss.volume = 1;
        } else {
          ss.volume = 0;
        }
      }
    }
  };
  const [data, setData] = useState({
    type: "",
    count: "",
    date: "",
    dose: "",
    age: "",
  });
  const history = useHistory();
  const vaccines = ["COVISHIELD", "COVAXIN"];
  const age = ["18-45", "45-more"];
  const Doses = ["Dose-1", "Dose-2"];
  useEffect(() => {
    if (!localStorage.getItem("staff_token")) {
      history.push("/staff");
    }
  }, []);
  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await addSlots(data);
      if (res.status == 200) {
        console.log(res);
        toast.success("Slots Added");
        history.push("/staff");
      } else {
        toast.error("Something went Wrong");
      }
    } catch (err) {
      toast.error("Can't add same slot again");
    }
  };
  return (
    <div className="w-full mt-8">
      <div className="flex-none lg:flex justify-around">
        <nav>
          <Link
            to="/tokendashboard"
            className="dashboard py-4 px-2 text-xl text-gray-500 font-semibold hover:text-gray-500 transition duration-300"
            title="Show details of all tokens"
          >
            {t("components.staff.dashboard")}
          </Link>
        </nav>
        <Router>
          <Route path="/tokendashboard" element={<TokenDashboard />} />
        </Router>
        <div className="w-full max-w-2xl ml-4">
          <form
            className="bg-gray-100 shadow-lg rounded px-8 pt-6 pb-8 mb-4"
            onSubmit={handlesubmit}
          >
            <div className="flex justify-center pt-4">
              <h1 className="text-gray-500 text-xl">
                {t("components.staff.slottitle")}
              </h1>
            </div>
            <br />
            <label className="required lining block text-gray-500 text-sm font-bold mb-2">
              <AiFillMedicineBox /> {t("components.offline.input6")}
            </label>
            <br />
            <select
              className="relative w-full max-w-xs mx-2 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={data.type}
              onChange={(e) => {
                setData({ ...data, type: e.target.value });
              }}
              required
            >
              <option
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                value=""
                selected
                disabled
                hidden
              >
                {t("components.downloadtoken.choose")}
              </option>
              {vaccines.map((x) => (
                <option
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  key={x}
                  value={x}
                >
                  {x}
                </option>
              ))}
            </select>
            <br />
            <label className="required lining block text-gray-500 text-sm font-bold mb-2">
              <FcLeftUp2 />
              {t("components.offline.input5")}
            </label>
            <br />
            <select
              className="relative w-full max-w-xs mx-2 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={data.age}
              onChange={(e) => {
                setData({ ...data, age: e.target.value });
              }}
              required
            >
              <option
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                value=""
                selected
                disabled
                hidden
              >
                {t("components.downloadtoken.choose")}
              </option>
              {age.map((x) => (
                <option
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  key={x}
                  value={x}
                >
                  {x}
                </option>
              ))}
            </select>
            <br />
            <label className="required lining block text-gray-500 text-sm font-bold mb-2">
              <GiOverdose />
              {t("components.offline.input7")}
            </label>
            <br />
            <select
              className="relative w-full max-w-xs mx-2 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={data.dose}
              onChange={(e) => {
                setData({ ...data, dose: e.target.value });
              }}
              required
            >
              <option
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                value=""
                selected
                disabled
                hidden
              >
                {t("components.downloadtoken.choose")}
              </option>
              {Doses.map((x) => (
                <option
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  key={x}
                  value={x}
                >
                  {x}
                </option>
              ))}
            </select>
            <br />
            <label className="required lining block text-gray-500 text-sm font-bold mb-2">
              <AiFillMedicineBox />
              {t("components.slotdata.header2")}
            </label>
            <br />
            <input
              className="shadow appearance-none border max-w-lg rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
              type="number"
              value={data.count}
              onChange={(e) => {
                setData({ ...data, count: e.target.value });
              }}
            />
            <br />
            <label className="required lining block text-gray-500 text-sm font-bold mb-2">
              <BsCalendarDate />
              {t("components.token.field4")}:
            </label>
            <br />
            <input
              className="shadow appearance-none border max-w-lg rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-300 border-gray-300"
              type="date"
              value={data.date}
              onChange={(e) => {
                setData({ ...data, date: e.target.value });
              }}
            />
            <br />
            <div className="flex justify-center pt-4">
              <button className="lining bg-gray-500 mt-3 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                <BsFillPatchCheckFill />
                {t("components.staff.verify")}
              </button>
            </div>
          </form>
        </div>
        <div>
          <Slotdata />
        </div>
        <div className="absolute left-0 top-0 mr-16 px-2">
          {mute ? (
            <GiSpeakerOff
              className="text-4xl"
              onClick={() => {
                setMute(false);
                handleSound(mute);
              }}
            />
          ) : (
            <GiSpeaker
              className="text-4xl"
              onClick={() => {
                setMute(true);
                handleSound(mute);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default StaffHome;
