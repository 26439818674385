import axios from "axios";
// const baseURL = "http://127.0.0.1:8000";
const baseURL = "https://arogyamahiti.iitdh.ac.in";
export const getOTP = async (phone_no) => {
  let url = `${baseURL}/server/api/v1/get_otp?phone_number=${phone_no}`;
  let res = await axios.get(url).then((response) => {
    return response.data;
  });
  return res;
};
export const verify_otp = async (user_details) => {
  let url = `${baseURL}/server/api/v1/verify_otp`;
  let res = axios.post(url, user_details).then((response) => {
    return response;
  });
  return res;
};

export const getBeneficiaries = async (user_details) => {
  let url = `${baseURL}/server/api/v1/get_beneficiaries`;
  let res = axios.post(url, user_details).then((response) => {
    return response;
  });
  return res;
};

export const generateToken = async (data) => {
  let url = `${baseURL}/server/api/v1/generate_token`;
  let res = axios.post(url, data).then((response) => {
    return response;
  });
  return res;
};

export const downloadofflineToken = async (data) => {
  let url = `${baseURL}/server/api/v1/downloadoffline_token`;
  let res = axios.post(url, data).then((response) => {
    return response;
  });
  return res;
};

export const generateofflineToken = async (data) => {
  let url = `${baseURL}/server/api/v1/generateoffline_token`;
  let res = axios.post(url, data).then((response) => {
    return response;
  });
  return res;
};

export const getslotData = async (data) => {
  let url = `${baseURL}/staffserver/getslot/`;
  let res = axios.get(url).then((response) => {
    return response;
  });
  return res;
};

export const getalltokens = async (data) => {
  let url = `${baseURL}/staffserver/getalltokens/?`;
  for (let key in data) {
    if (key !== "") {
      url += `${key}=${data[key]}&`;
    }
  }
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + JSON.parse(localStorage.getItem("staff_token")),
  };
  let res = axios.get(url, { headers: headers }).then((response) => {
    return response;
  });
  return res;
};

export const getToken = async (user_details) => {
  let url = `${baseURL}/server/api/v1/get_token`;
  let res = axios.post(url, user_details).then((response) => {
    return response;
  });
  return res;
};

export const getStafftoken = async (user_details) => {
  let url = `${baseURL}/staffserver/api-token-auth/`;
  let res = axios.post(url, user_details).then((response) => {
    return response;
  });
  return res;
};

export const addSlots = async (user_details) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Token " + JSON.parse(localStorage.getItem("staff_token")),
  };
  let url = `${baseURL}/staffserver/addslot/`;
  let res = axios
    .post(url, user_details, { headers: headers })
    .then((response) => {
      return response;
    });
  return res;
};
