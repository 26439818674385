import React from "react";
import { useTranslation } from "react-i18next";

function Redirect() {
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-gray-100 shadow-lg max-w-2xl mt-32 mx-auto px-4">
      <br />
      <div className="font-medium tracking-wider text-2xl mx-8 mt-8">
        {t("components.redirect.title")}
      </div>
      <br />
      <div className="mx-32 text-xl mb-8">
        {t("components.redirect.click")}
        <a
          className="mx-1 text-gray-300"
          href="https://selfregistration.cowin.gov.in/"
        >
          {t("components.redirect.a")}
        </a>
        {t("components.redirect.clicknext")}
      </div>
      <br />
    </div>
  );
}

export default Redirect;
